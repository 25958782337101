const envSettings = window as any;
export class Config {
  static REACT_APP_API_SAGRADA = envSettings.REACT_APP_API_SAGRADA;
  static REACT_APP_API_SAGRADA_ADYEN = envSettings.REACT_APP_API_SAGRADA_ADYEN;
  static REACT_APP_API_COLOSSEUM = envSettings.REACT_APP_API_COLOSSEUM;
  static REACT_APP_API_PARC_GUELL = envSettings.REACT_APP_API_PARC_GUELL;
  static REACT_APP_API_VATICAN = envSettings.REACT_APP_API_VATICAN;
  static REACT_APP_API_VERSAILLES = envSettings.REACT_APP_API_VERSAILLES;
  static REACT_APP_API_ACROPOLIS = envSettings.REACT_APP_API_ACROPOLIS;
  static REACT_APP_API_HOPONHOPOFF = envSettings.REACT_APP_API_HOPONHOPOFF;
  static REACT_APP_API_SEINE = envSettings.REACT_APP_API_SEINE;
  static REACT_APP_API_ALERTS = envSettings.REACT_APP_API_ALERTS;
  static REACT_APP_API_MONTJUIC = envSettings.REACT_APP_API_MONTJUIC;
  static REACT_APP_API_TICKETDOOR = envSettings.REACT_APP_API_TICKETDOOR;
}
