import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ErrorIcon from "@mui/icons-material/Error";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { useLocation, Link, Outlet } from "react-router-dom";
import { useState } from "react";
import { Collapse, ListItemButton } from "@mui/material";
import { barItems } from "./_itemData";
import {
  ExpandLess,
  ExpandMore,
  Person,
  ListAlt,
  Home,
  CreditCard,
  BarChartOutlined,
  SupportAgent
} from "@mui/icons-material";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

type Props = {
  open: boolean;
  setOpen: Function;
  children?: any;
};

export default function PersistentDrawerRight(props: Props) {
  const location = useLocation();
  const [visible, isVisible] = useState(false);
  const [listState, setListState] = useState(
    barItems.map((item) => item.opened)
  );
  const [open2, setOpen2] = useState(false);

  React.useEffect(() => {
    location.pathname !== "/login" ? isVisible(true) : isVisible(false);
  }, [location]);

  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const sidebarExpandHandler = (i: number): void => {
    console.log(listState);
    const newListState = listState;
    newListState[i] = !newListState[i];
    setListState(newListState);
    setOpen2(!open2);
  };

  //console.log('barItems');
  //console.log(barItems);


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        style={{ visibility: visible ? "visible" : "hidden" }}
        position="fixed"
        open={open}
      >
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            Panel
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        {visible && <DrawerHeader />}
        <Outlet />
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton className="logo-button" onClick={handleDrawerClose} sx={{display: "flex", justifyContent: "center"}}>
            <img
              alt="logo"
              src="/static/logo-ticketdoor.svg"
            />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem button component={Link} to={`/home`} sx={{ pl: 2 }}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component={Link} to={`/support`} sx={{ pl: 2 }} disabled>
            <ListItemIcon>
              <SupportAgent />
            </ListItemIcon>
            <ListItemText primary="Support" />
          </ListItem>
          <ListItem button component={Link} to={`/overview`} sx={{ pl: 2 }}>
            <ListItemIcon>
              <BarChartOutlined />
            </ListItemIcon>
            <ListItemText primary="Overview" />
          </ListItem>
          <ListItem button component={Link} to={`/finance`} sx={{ pl: 2 }}>
            <ListItemIcon>
              <CreditCard />
            </ListItemIcon>
            <ListItemText primary="Finance" />
          </ListItem>
          <ListItem button component={Link} to={`/pendings`} sx={{ pl: 2 }}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Pendings" />
          </ListItem>
          <ListItem button component={Link} to={`/bookings`} sx={{ pl: 2 }}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Bookings" />
          </ListItem>
          <ListItem button component={Link} to={`/availability`} sx={{ pl: 2 }}>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary="Availability" />
          </ListItem>
          {barItems.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <ListItemButton
                  key={item.label}
                  onClick={() => sidebarExpandHandler(index)}
                >
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                  {listState[index] === true ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={listState[index] === true}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    <ListItem
                      button
                      component={Link}
                      to={`${item.to}/customers`}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <Person />
                      </ListItemIcon>
                      <ListItemText primary="Customers" />
                    </ListItem>
                    {(item.to === "/sagradanew" || item.to === "/sagrada-adyen" || item.to === "/parkguellnew" || item.to === "/acropolis" || item.to === "/hoponhopoff" || item.to === "/seine" || item.to === "/montjuic") ? (<></>) : (<ListItem
                      button
                      component={Link}
                      to={`${item.to}/reports`}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <ListAlt />
                      </ListItemIcon>
                      <ListItemText primary="Reports" />
                    </ListItem>)}
                    {(item.to === "/sagradanew" || item.to === "/sagrada-adyen" || item.to === "/parkguellnew" || item.to === "/acropolis" || item.to === "/hoponhopoff" || item.to === "/seine" || item.to === "/montjuic") ? (<></>) : (<ListItem
                      button
                      component={Link}
                      to={`${item.to}/cards`}
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <CreditCard />
                      </ListItemIcon>
                      <ListItemText primary="Cards" />
                    </ListItem>)}
                    {item.label === 'Coliseo' ?
                      <React.Fragment>
                        <ListItem
                          button
                          component={Link}
                          to={`${item.to}/presale`}
                          sx={{ pl: 4 }}
                        >
                          <ListItemIcon>
                            <CreditCard />
                          </ListItemIcon>
                          <ListItemText primary="Presale" />
                        </ListItem>
                        <ListItem
                          button
                          component={Link}
                          to={`${item.to}/stock`}
                          sx={{ pl: 4 }}
                        >
                          <ListItemIcon>
                            <CreditCard />
                          </ListItemIcon>
                          <ListItemText primary="Stock" />
                        </ListItem>
                        <ListItem
                          button
                          component={Link}
                          to={`${item.to}/presale-orders`}
                          sx={{ pl: 4 }}
                        >
                          <ListItemIcon>
                            <CreditCard />
                          </ListItemIcon>
                          <ListItemText primary="Presale Orders" />
                        </ListItem>
                      </React.Fragment>
                      : ''}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          })}
          <ListItem button component={Link} to={`/alerts`} sx={{ pl: 2 }}>
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            <ListItemText primary="Alerts" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
