import "./App.scss";
import { createTheme, ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Outer from "./components/Outer/Outer";
import Alerts from "./pages/alerts/Alerts";
import Home from "./pages/Home/Home";
import loadable from "@loadable/component";
import BookingDetails from "./pages/customers/BookingDetails";
// import from "./pages/park-guell-new/Customers/NewOrder";

const LoginPage = loadable(() => import("./pages/Login"));
const NotFoundPage = loadable(() => import("./pages/404"));
const ParkGuellCustomers = loadable(
  () => import("./pages/park-guell/Customers/Customers")
);
const ParkGuellReports = loadable(
  () => import("./pages/park-guell/Reports/Reports")
);
const ParkGuellCards = loadable(() => import("./pages/park-guell/Cards/Cards"));
const VaticanCustomers = loadable(
  () => import("./pages/vatican/Customers/Customers")
);
const VaticanReports = loadable(
  () => import("./pages/vatican/Reports/Reports")
);
const VaticanCards = loadable(() => import("./pages/vatican/Cards/Cards"));
const NewSagradaCustomers = loadable(
  () => import("./pages/sagrada2/Customers/Customers")
);
const NewSagradaReports = loadable(
  () => import("./pages/sagrada2/Reports/Reports")
);
const SagradaCards = loadable(() => import("./pages/sagrada2/Cards/Cards"));

const NewColoseumCustomers = loadable(
  () => import("./pages/colosseum2/Customers/Customers")
);
const NewColosseumReports = loadable(
  () => import("./pages/colosseum2/Reports/Reports")
);
const ColosseumPresale = loadable(
  () => import("./pages/colosseum2/Presale/Presale")
);
const ColosseumStock = loadable(
  () => import("./pages/colosseum2/Presale/Stock")
);
const ColosseumPresaleOrders = loadable(
  () => import("./pages/colosseum2/Presale/PresaleOrders")
);
const ColosseumCards = loadable(() => import("./pages/colosseum2/Cards/Cards"));

const VersaillesCustomers = loadable(
  () => import("./pages/versailles/Customers/Customers")
);
const VersaillesReports = loadable(
  () => import("./pages/versailles/Reports/Reports")
);
const VersaillesCards = loadable(
  () => import("./pages/versailles/Cards/Cards")
);

const NewParkGuellCustomers = loadable(
  () => import("./pages/park-guell-new/Customers/Customers")
);

// const NewOrderParkGuell = loadable(
//   () => import("./pages/park-guell-new/Customers/NewOrder")
// );

const NewSafaCustomers = loadable(
  () => import("./pages/sagrada-new/Customers/Customers")
);
const CreateOrdersSafa = loadable(() => import("./pages/sagrada-new/Customers/CreateOrder"));

const Acropolis = loadable(
  () => import("./pages/acropolis/Customers/Customers")
);

const HopOnHopOff = loadable(
  () => import("./pages/hop-on-hop-off/Customers/Customers")
);

const Seine = loadable(() => import("./pages/seine/Customers/Customers"));

const Montjuic = loadable(() => import("./pages/montjuic/Customers/Customers"));

const SagradaAdyenCustomers = loadable(
  () => import("./pages/sagrada-adyen/Customers/Customers")
);

const Overview = loadable(() => import("./pages/overview/Overview"));

const Pendings = loadable(() => import("./pages/customers/Pendings"));

const Bookings = loadable(() => import("./pages/customers/Bookings"));

const Finance = loadable(() => import("./pages/finance/Finance"));

const Availability = loadable(() => import("./pages/availability/Availability"));

const CustomerService = loadable(() => import("./pages/customers/CustomerService"));


const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
    errorElement: <NotFoundPage />,
    children: [],
  },
  {
    path: "/home",
    element: <Outer />,
    children: [{ path: "", element: <Home /> }],
  },
  {
    path: "/support",
    element: <Outer />,
    children: [{ path: "", element: <CustomerService />}],
  },
  {
    path: "/overview",
    element: <Outer />,
    children: [{ path: "", element: <Overview /> }],
  },
  {
    path: "/bookings/:id",
    element: <Outer />,
    children: [{ path: "", element: <BookingDetails /> }],
  },
  {
    path: "/pendings",
    element: <Outer />,
    children: [{ path: "", element: <Pendings /> }],
  },
  {
    path: "/bookings",
    element: <Outer />,
    children: [{ path: "", element: <Bookings /> }],
  },
  {
    path: "/finance",
    element: <Outer />,
    children: [{ path: "", element: <Finance /> }],
  },
  {
    path: "/availability",
    element: <Outer />,
    children: [{ path: "", element: <Availability /> }],
  },
  {
    path: "/montjuic",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <Montjuic />,
      },
    ],
  },
  {
    path: "/seine",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <Seine />,
      },
    ],
  },
  {
    path: "/hoponhopoff",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <HopOnHopOff />,
      },
    ],
  },
  {
    path: "/acropolis",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <Acropolis />,
      },
    ],
  },
  {
    path: "sagrada",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <NewSagradaCustomers />,
      },
      {
        path: "reports",
        element: <NewSagradaReports />,
      },
      {
        path: "cards",
        element: <SagradaCards />,
      },      
    ],
  },
  {
    path: "sagradanew",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <NewSafaCustomers />,
      },
      {
        path: "create-order",
        element: <CreateOrdersSafa />,
      },
    ],
  },
  {
    path: "sagrada-adyen",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <SagradaAdyenCustomers />,
      },
    ],
  },
  {
    path: "colosseum",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <NewColoseumCustomers />,
      },
      {
        path: "reports",
        element: <NewColosseumReports />,
      },
      {
        path: "cards",
        element: <ColosseumCards />,
      },
      {
        path: "presale",
        element: <ColosseumPresale />,
      },
      {
        path: "stock",
        element: <ColosseumStock />,
      },
      {
        path: "presale-orders",
        element: <ColosseumPresaleOrders />,
      },
    ],
  },
  {
    path: "parkguell",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <ParkGuellCustomers />,
      },
      {
        path: "reports",
        element: <ParkGuellReports />,
      },
      {
        path: "cards",
        element: <ParkGuellCards />,
      },
    ],
  },
  {
    path: "parkguellnew",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <NewParkGuellCustomers />,
      },
      {
        path: "reports",
        element: <ParkGuellReports />,
      },
      {
        path: "cards",
        element: <ParkGuellCards />,
      },
      // {
      //   path: "new-order",
      //   element: <NewOrderParkGuell />,
      // },
    ],
  },
  {
    path: "vatican",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <VaticanCustomers />,
      },
      {
        path: "reports",
        element: <VaticanReports />,
      },
      {
        path: "cards",
        element: <VaticanCards />,
      },
    ],
  },
  {
    path: "versailles",
    element: <Outer />,
    children: [
      {
        path: "customers",
        element: <VersaillesCustomers />,
      },
      {
        path: "reports",
        element: <VersaillesReports />,
      },
      {
        path: "cards",
        element: <VersaillesCards />,
      },
    ],
  },
  // {
  //   path: "sagrada2",
  //   element: <Outer />,
  //   children: [
  //     {
  //       path: "customers",
  //       element: <NewSagradaCustomers />,
  //     },
  //     {
  //       path: "reports",
  //       element: <NewSagradaReports />,
  //     },
  //   ],
  // },
  // {
  //   path: "colosseum2",
  //   element: <Outer />,
  //   children: [
  //     {
  //       path: "customers",
  //       element: <NewColoseumCustomers />,
  //     },
  //     {
  //       path: "reports",
  //       element: <NewColosseumReports />,
  //     },
  //   ],
  // },
  {
    path: "alerts",
    element: <Outer />,
    children: [
      {
        path: "",
        element: <Alerts />,
      },
    ],
  },
]);

function App() {
  const theme = createTheme({});
  
  // get the expiration time of the token from local storage 
  const expirationTime = localStorage.getItem("expirationTime");
  const currentTime = new Date().getTime();
  // if the expiration time is less than the current time, then logout
  if (expirationTime && currentTime > parseInt(expirationTime)) {
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("ticketdoorToken");
    window.location.href = "/";
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  );
}

export default App;
